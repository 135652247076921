<template>
	<el-dialog title="巡诊记录" custom-class="showParks" :visible.sync="showVisible" width="800px" center>
		<Table
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-page="false"
			:is-show-selection="false"
			:is-show-index="false"
			:show-operation="false"
			:loading="loading"
			:tableHeight="0"
		/>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="showVisible = false">关 闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			showVisible: false,
			tableData: [],
			tableHead: [
				{
					label: '巡诊人员姓名',
					prop: 'workerName',
					formatter: (row) => {
						return row.workerName || '-';
					},
				},
				{
					label: '巡诊人员电话',
					prop: 'workerPhoneNumber',
					formatter: (row) => {
						return row.workerPhoneNumber || '-';
					},
				},
				{
					label: '被访人',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '被访人联系方式',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '详细地址',
					prop: 'homeAddress',
					formatter: (row) => {
						return row.homeAddress || '-';
					},
				},
				{
					label: '工作记录',
					prop: 'description',
					formatter: (row) => {
						return row.description || '-';
					},
				},
			],
		};
	},
	props: [],
	watch: {},
	methods: {
		// 处理拿到的公园数据
		init(data) {
			this.showVisible = true;
			this.loading = true;
			this.$http
				.get(this.api['TourWorkLogs#index'].href, {
					params: {
						discriminationCode: 'T',
						planId: data.id,
					},
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .showParks {
	padding-bottom: 26px !important;
}
</style>